







import { Component, Vue } from 'vue-property-decorator'
import store from './store/index'
import TheToaster from '@/components/toast/TheToaster.vue'
import { ROLES } from './model/Role'

@Component({
  store,
  components: { TheToaster },
})
export default class App extends Vue {
  created() {
    this.$store.dispatch('getLoggedUser')

    if (this.$store.getters.loggedUser.role === ROLES.USER) {
      if (!this.$store.getters.projects.length) {
        this.$store.dispatch('getProjects').then(() => {
          this.$store.dispatch('nav/refresh', this.$route.path)
        })
      }
    } else if (this.$store.getters.isAdmin) {
      if (!this.$store.getters.users.length) {
        this.$store.dispatch('getUsers')
      }
    }
  }
}
