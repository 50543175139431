export enum CATEGORIES_CHARGES {
  FOURNITURES_ADMINISTRATIVES = '1',
  FOURNITURES_NON_STOCKEES = '2',
  PETIT_EQUIPEMENT = '3',
  ENTRETIEN_REPARATION = '4',
  CHARGES_LOCATIVES = '5',
  ASSURANCES = '6',
  HONORAIRES = '7',
  PUBLICITE = '8',
  FRAIS_POSTAUX = '9',
  FRAIS_BANQUAIRES = '10',
  IMPOTS_FONCIER = '11',
  DIVERS = '12',
}

export interface AccountingExpenses {
  id: number
  date?: string
  fournisseur?: string
  nature?: string
  ttc?: number
  ht?: number
  tva?: number
  categorie?: string
  financialAccountingId?: number
  datePreBilan?: string
}
