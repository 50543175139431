import { GetterTree, MutationTree, ActionTree } from 'vuex'
import DashboardApi from '@/api/DashboardApi'
import { loadLanguageAsync } from '@/locales'

class State {
  lang = 'fr'
}

const state = new State()

export type localeType = {}

const getters: GetterTree<State, localeType> = {
  lang: state => state.lang,
}

const actions: ActionTree<State, localeType> = {
  changeLang({ commit }, lang: string) {
    commit('changeLang', lang)
  },
}

const mutations: MutationTree<State> = {
  changeLang(state, lang: string) {
    state.lang = lang
    DashboardApi.lang = lang
    loadLanguageAsync(lang)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
