import { CATEGORIES_CHARGES } from '@/model/AccountingExpenses'
import { ACCOUNTING_GESTION } from '@/model/AccountingFinancial'
import {
  FORMES_JURIDIQUES,
  LOCATION_ETATS,
  REGIMES_FISCAL,
  REGIMES_FISCAL_RNRS,
  REGIMES_TVA_DECLARATION,
  TERRAINS,
} from '@/model/Project'
import { GENDERS, PERSONNES } from '@/model/User'
import { LocaleMessages } from 'vue-i18n'

const messages: LocaleMessages = {
  fr: {
    hello: `bonjour`,
    yes: `Oui`,
    no: `Non`,
    user: `utilisateur`,
    users: `utilisateurs`,
    project: `location`,
    projects: `locations`,
    nbUser: `aucun utilisateur | {n} utilisateur | {n} utilisateurs`,
    login: `Se connecter`,
    usernameOrEmail: `Nom d'utilisateur ou email`,
    password: `Mot de passe`,
    connection: `Connexion`,
    forgotMyPassword: `mot de passe oublié ?`,
    home: `accueil`,
    myAccount: `mon compte`,
    logout: `déconnexion`,
    addNewLine: `Ajouter une ligne`,
    add: `ajouter`,
    newUser: `nouvel utilisateur`,
    addExistingUser: `ajouter un utilisateur existant`,
    date: `Date`,
    expand: `ouvrir`,
    collapse: `fermer`,
    noData: `données indisponibles`,
    delete: `supprimer`,
    duplicate: `dupliquer`,
    activated: `activé`,
    desactivated: `désactivé`,
    edit: `modifier`,
    username: `Nom d'utilisateur`,
    email: `Email`,
    role: `role`,
    noAccountYet: `Pas encore de compte ?`,
    handleFileSecure: `Pour gérer vos fichiers avec efficacité, Gérer Mon LMNP vous propose un
    accès de dépôt de vos pièces comptables 100% sécurisé. Vous aurez accès à
    votre espace de dépôt en suivant les instructions ci-dessous.`,
    openInANewTab: `Ouvrir dans un nouvel onglet`,
    noFileAccountYet: `Vous ne disposez pas encore d'un accès à l'espace de dépot de document ?`,
    askForFileAccount: `Demander à Gérer Mon LMNP d'en créer un :`,
    askForFileAccountBtn: `Demander un accès à l'espace de dépot`,
    step: `Étape {n}`,
    day: `jour`,
    days: `jours`,
    resetPassword: `Réinitialisation du mot de passe`,
    createProject: `Créer la location`,
    tutorial: `Tutoriel`,
    registration: `Inscription`,
    saveAllData: `Sauvegarder toutes les données`,
    uploadDocument: `Déposer un document`,
    accounting: `Comptabilité`,
    helpSeveralProjects: `Si vous avez plusieurs biens en location, veuillez créer un bien par lot`,
    Genders: {
      '': `Genre non défini`,
      null: `Genre non défini`,
      undefined: `Genre non défini`,
      [GENDERS.MALE]: `Homme`,
      [GENDERS.FEMALE]: `Femme`,
    },
    Project: {
      projectName: `Nom de la location`,
      manager: `Gestionnaire`,
      address: `Adresse`,
      city: `Ville`,
      postalCode: `Code postal`,
      siret: `SIRET`,
      taxesCenter: `Centre des impôts`,
      terrain: `Localisation de la location`,
      Terrain: {
        '': `Terrain non défini`,
        null: `Terrain non défini`,
        undefined: `Terrain non défini`,
        [TERRAINS.CAMPAGNE]: `Campagne`,
        [TERRAINS.VILLE]: `Ville (exemples : Metz, Nancy, Versailles, Grenoble, Brest, Cannes, Avignon, Ajaccio, Perpignan…)`,
        [TERRAINS.GRANDE_VILLE]: `Grande ville (uniquement Marseille, Lyon, Toulouse, Nice, Nantes, Strasbourg, Montpellier, Bordeaux, Lille et Rennes)`,
        [TERRAINS.PARIS_INTRA]: `Paris intra-muros`,
      },
      grosoeuvre: `Gros œuvre`,
      facade: `Façade, étanchéité`,
      agencement: `Agencement`,
      Etats: {
        '': `État non défini`,
        null: `État non défini`,
        undefined: `État non défini`,
        [LOCATION_ETATS.TRES_BON]: `Très bon état - Aucun travaux à prévoir`,
        [LOCATION_ETATS.BON]: `Bon état`,
        [LOCATION_ETATS.VETUSTE]: `Vétuste`,
      },
      askDelete: `Êtes-vous sûr de vouloir supprimer cette location ?`,
    },
    Pricing: {
      cga: `Adhésion au CGA`,
      tva: `Soumis à la TVA`,
      creation: `Immatriculation par Gérer Mon LMNP (L’activité nécessite d’être immatriculée auprès du SIE compétent pour obtenir un numéro SIRET)`,
      cgaAvantages: `Les avantages du CGA`,
      tvaAvantages: `Dans quels cas mon LMNP est-il soumis à TVA ?`,
      info1: `Conformement à la lettre de mission intialisement signée, nous vous rappelons qu'un montant supplémentaire est prévu (180 €TTC) pour chaque nouveau bien.`,
      prixFixe: `Prix immatriculation : {0} (soit {1} TTC)`,
      prixFixeStrong: `{n} HT une seule fois`,
      prixFixe2: `À la création de votre activité et à chaque acquisition supplémentaire : {0} (soit {1} TTC)`,
      prixFixe2Strong: `{n} HT une seule fois`,
      prixAnnuel: `Prix annuel : {0} (soit {1} TTC / an)`,
      prixAnnuelStrong: `{n} HT / an`,
      prixLocation: `Prix des locations supplémentaires : {0} (soit {1} TTC / an)`,
      prixLocationStrong: `{n} HT / an`,
      prixFinal: `Prix final après déduction des impôts : {0} (soit {1} TTC / an)`,
      prixFinalStrong: `{n} HT / an`,
    },
    BankDetail: {
      bankName: `Nom du débiteur`,
      address: `Adresse`,
      city: `Ville`,
      postalCode: `Code postal`,
      iban: `Numéro de compte IBAN`,
      bic: `Code BIC`,
    },
    Cga: {
      nom: `Nom`,
      prenom: `Prénom`,
      dateNaissance: `Date de naissance`,
      mobile: `Mobile`,
      email: `Email`,
      siret: `SIRET`,
      dateCreation: `Date de création`,
      denominationSocial: `Dénomination sociale`,
      adressePro: `Adresse professionnelle`,
      codePostalPro: `Ville`,
      villePro: `Code postal`,
      adresseCorrespondance: `Adresse de correspondance`,
      codePostalCorrespondance: `Ville`,
      villeCorrespondance: `Code postal`,
      dateDebutExercice: `Date de début d'exercice`,
      dateFinExercice: `Date de fin d'exercice`,
      premiereAdhesion: `Première adhésion`,
      premiereAdhesionNom: `Préciser la précédente adhésion`,
      nomTransfert: `Nom de centre`,
      adresseTransfert: `Adresse`,
      villeTransfert: `Ville`,
      codePostalTransfert: `Code postal`,
      FormeJuridique: {
        '': `Non défini`,
        null: `Non défini`,
        undefined: `Non défini`,
        [FORMES_JURIDIQUES.ENTREPRISE_INDIVIDUELLE]: `Entreprise individuelle (Bien acheté en nom propre ou indivision)`,
        [FORMES_JURIDIQUES.SARL_FAMILLE]: `SARL de famille`,
        [FORMES_JURIDIQUES.AUTRE]: `Autre`,
      },
      regimeFiscal: `Régime fiscal`,
      RegimeFiscal: {
        '': `Non défini`,
        null: `Non défini`,
        undefined: `Non défini`,
        [REGIMES_FISCAL.BIC]: `BIC`,
        [REGIMES_FISCAL.BA]: `BA`,
        [REGIMES_FISCAL.IS]: `IS`,
        [REGIMES_FISCAL.MICRO_BIC]: `Micro-BIC / Auto-entreprise`,
      },
      regimeFiscalRnRs: `Liasse fiscale`,
      RegimeFiscalRnRs: {
        '': `Non défini`,
        null: `Non défini`,
        undefined: `Non défini`,
        [REGIMES_FISCAL_RNRS.REEL_NORMAL]: `Réel normal`,
        [REGIMES_FISCAL_RNRS.REEL_SIMPLIFIE]: `Réel simplifié`,
      },
      regimeTvaDeclaration: `Déclaration de la TVA`,
      RegimeTvaDeclaration: {
        '': `Non défini`,
        null: `Non défini`,
        undefined: `Non défini`,
        [REGIMES_TVA_DECLARATION.TRIMESTRIEL]: `Trimestrielle`,
        [REGIMES_TVA_DECLARATION.ANNUEL]: `Annuelle`,
      },
    },
    Accounting: {
      incomesTabTitle: `Détails des loyers encaissés`,
      loansTabTitle: `Mon échéancier`,
      expensesTabTitle: `Charges de l’année (payées ou non)`,
      cautionsTabTitle: `Suivi cautions`,
      gestion: `Bien géré :`,
      Gestion: {
        [ACCOUNTING_GESTION.DIRECT]: `en direct`,
        [ACCOUNTING_GESTION.RESIDENCE_SERVICE]: `par Résidence de Services`,
      },
      personalUsage: `Nombre de jours d’occupation personnelle`,
      totalIncomesTTC: `Total des produits : {0} € TTC`,
      totalExpensesTTC: `Total des charges : {0} € TTC`,
      totalDiffTTC: `Différence : {0} € TTC`,
      totalIncomesHT: `Total des produits : {0} € HT`,
      totalExpensesHT: `Total des charges : {0} € HT`,
      totalDiffHT: `Différence : {0} € HT`,
      closeCompta: `Clôturer ma comptabilité {year}`,
      lockInfo1: `En verrouillant vos données comptables, vous confirmez que celles-ci sont exactes et complètes.`,
      lockInfo2: `Les experts de Gérer Mon LMNP ne pourrons générer votre bilan comptable qu'une fois celles-ci verrouillées. Attention, une fois verrouillées, il ne vous sera plus permis de les modifier.`,
      lockInfo3: `Vous devez aussi vous assurer de nous avoir transmis tous les documents relatifs à vos écritures (factures, baux locatifs, quittance d'assurance, etc...). Pour transmettre des documents, vous pouvez ouvrir votre espace de dépôt de document en : {0}.`,
      lockInfo3Detail: `cliquant ici`,
      lockInfo4: `Vous confirmez que votre logement meublé est équipé des éléments minimum prévus par le : {0}.`,
      lockInfo4Detail: `Décret du 31 juillet 2015`,
      close: `Clôturer`,
      downloadBilan: `Télécharger le bilan Excel`,
      unlockData: `Déverouiller la saisie pour l'utilisateur`,
      lockData: `Verouiller la saisie pour l'utilisateur`,
    },
    Caution: {
      dateDebutLocation: `Date début location`,
      dateFinLocation: `Date fin location`,
      locataire: `Locataire`,
      solde: `Solde caution (€)`,
      cautionEncaissee: `Encaissée (€ TTC)`,
      dateEncaissement: `Date encaissement`,
      cautionRestituee: `Restituée (€ TTC)`,
      dateRestitution: `Date restitution`,
      totalEncaisse: `Total encaissé : {0} €`,
      totalRestitue: `Total restitué : {0} €`,
      totalSolde: `Total solde : {0} €`,
    },
    Expense: {
      date: `Date`,
      fournisseurs: `Nom du fournisseur`,
      nature: `Nature`,
      ttc: `€ TTC`,
      ht: `€ HT`,
      tva: `€ TVA`,
      Categorie: {
        '': `Non défini`,
        null: `Non défini`,
        undefined: `Non défini`,
        [CATEGORIES_CHARGES.FOURNITURES_ADMINISTRATIVES]: `Fournitures administratives`,
        [CATEGORIES_CHARGES.FOURNITURES_NON_STOCKEES]: `Fournitures non stockées (eau/gaz/électricité)`,
        [CATEGORIES_CHARGES.PETIT_EQUIPEMENT]: `Petit équipement (Vaisselle, électroménager , meubles …)`,
        [CATEGORIES_CHARGES.ENTRETIEN_REPARATION]: `Entretien/Réparation`,
        [CATEGORIES_CHARGES.CHARGES_LOCATIVES]: `Charges locatives`,
        [CATEGORIES_CHARGES.ASSURANCES]: `Assurances`,
        [CATEGORIES_CHARGES.HONORAIRES]: `Honoraires (agence/compta/CGA)`,
        [CATEGORIES_CHARGES.PUBLICITE]: `publicité`,
        [CATEGORIES_CHARGES.FRAIS_POSTAUX]: `Frais postaux/téléphone`,
        [CATEGORIES_CHARGES.FRAIS_BANQUAIRES]: `Frais bancaires (hors intérêts emprunts)`,
        [CATEGORIES_CHARGES.IMPOTS_FONCIER]: `Impôts foncier`,
        [CATEGORIES_CHARGES.DIVERS]: `Divers`,
      },
    },
    Income: {
      datePaiement: `Date de paiement`,
      locataire: `Locataire`,
      loyerNet: `Loyer (€ TTC)`,
      loyerBrut: `Loyer (€ HT)`,
      loyerTVA: `TVA (€)`,
      chargesLocNet: `Charges Loc. (€ TTC)`,
      chargesLocBrut: `Charges Loc. (€ HT)`,
      chargesLocTVA: `TVA (€)`,
      taxeSejour: `Taxe de séjour (€)`,
    },
    Loan: {
      date: `Date`,
      amount: `Montant global de l’échéance mensuelle (€)`,
      capital: `Capital (€)`,
      interest: `Intérêts (€)`,
      insurance: `Assurance (€)`,
      capitalRemaining: `Capital restant dû au 31/12/{year}`,
      pleaseGiveContract: `Merci de bien vouloir nous transmettre votre contrat d'emprunt et le
      tableau d'amortissement correspondant.`,
    },
    roles: {
      superadmin: 'superadmin',
      admin: 'administrateur',
      user: 'utilisateur',
      1: 'superadmin',
      2: 'administrateur',
      3: 'utilisateur',
    },
    User: {
      username: `Nom d'utilisateur`,
      email: `Email`,
      firstname: `Prénom`,
      lastname: `Nom`,
      gender: `Genre`,
      birthdate: `Date de naissance`,
      address: `Adresse`,
      city: `Ville`,
      postalCode: `Code postal`,
      mobile: `Portable`,
      denominationSociale: `Dénomination sociale`,
      siret: `SIRET`,
      Personne: {
        physique: `Personne physique`,
        morale: `Personne morale`,
        [PERSONNES.PHYSIQUE]: `Personne physique`,
        [PERSONNES.MORALE]: `Personne morale`,
      },
    },
    needHelp: `Vous avez besoin d'aide ?`,
    downloadBilan: `Télécharger le bilan`,
    generateMissionLetter: `Générer ma lettre de mission`,
    helpAboutPricing: `En savoir plus sur les tarifs`,
    helpAboutPricing2: `Plus de détail sur nos tarifs`,
    avisSituationSirene: `Veuillez vous référer à votre avis SIRENE, disponible sur `,
    cancelEdition: `Annuler les modifications`,
    validateEdition: `Valider les modifications`,
    chooseNewPassword: 'choisissez votre nouveau mot de passe',
    validate: 'valider',
    typeUsernameOrPassword:
      "saisissez votre nom d'utilisateur ou votre adresse email",
    choosePasswordActivateAccount:
      'choisissez votre mot de passe pour activer votre compte',
    activateAccount: 'activer le compte',
    cancel: 'annuler',
    editMyData: 'modifier mes infos',
    editMyPassword: 'modifier mon mot de passe',
    deleteMyAccount: 'supprimer mon compte',
    oldPassword: 'ancien mot de passe',
    iAmSur: 'je suis sûr',
    deleteAccountPassphrase: 'supprimer le compte',
    askIfSurDeleteOwnAccount:
      'Vous êtes bien sûr de vouloir supprimer ce compte ? Pour pouvoir supprimer définitivement ce compte, taper la phrase suivante :',
    editImage: "Modifier l'image",
    addImage: 'Ajouter une image',
    welcomeHome: 'Bienvenue dans votre espace ',
    welcomeHomeAdmin: 'Bienvenue dans votre espace administrateur ',
    goodbye: 'Au revoir ',
    simulator: 'Simulateur',
    taxation: `Votre barème d'imposition`,
    propertyValue: `Valeur de votre bien`,
    propertyValueTitle: `Valeur du bien`,
    furnitureValue: `Valeur du mobilier`,
    amountBorrowed: `Montant emprunté`,
    interestRate: `Taux d'intérêt`,
    termOfLoan: `Durée du prêt (années)`,
    annualRent: `Montant annuel des loyers perçus`,
    annualCharges: `Montant annuel des charges`,
    annualChargesHint: `Taxes foncières, assurance...`,
    abatement: `Abattement 50% pour micro BIC`,
    taxeBaseMicro: `Base imposable (micro)`,
    annualTaxesMicro: `Impôts annuels (y compris CSG/CRDS) au Micro BIC `,
    rent: `Loyer`,
    depreciation: `Amortissement`,
    loanInterest: `Intérêts emprunt`,
    charges: `Charges`,
    taxeBaseReal: `Base imposable (réel)`,
    annualTaxesReal: `Impôts annuels (y compris CSG/CRDS) au Régime BIC réel`,
    familySituation: `Tranche marginale d’imposition`,
    mortgageLoan: `Données du prêt immobilier`,
    incomeExpenses: `produits / charges`,
    income: `produits`,
    expenses: `charges`,
    microRegime: `LMNP Régime micro`,
    realRegime: `LMNP Régime réel`,
    unfurnishedRental: `Location nue relevant des Revenus Fonciers`,
    taxeBaseUnfurnished: `Base imposable (location nue)`,
    annualTaxesUnfurnished: `Impôts annuels (y compris CSG/CRDS) au Régime foncier`,
    createNewAccount: `créer un nouveau compte`,
    createAccount: `Créer le compte`,
    askResetPassword: `Ré-initialiser votre mot de passe`,
    askCreateNewAccount: `Faire une demande de création de compte`,
    handleFiles: `déposer et gérer mes documents`,
    generateEngagementLetter: `générer ma lettre de mission`,
    handleAccounting: `saisir les données de ma comptabilité`,
    engagementLetter: `Lettre de mission`,
    myProjects: `mes locations`,
    newProject: `+ Nouvelle location`,
    myNewProject: `Nouvelle location`,
    infoNewProject: `Veuillez vous référer à votre lettre de mission principale, et aux tarifs applicables pour l’ajout d’un bien ou lot supplémentaire`,
    deleteProject: `Supprimer la location`,
    name: `Nom`,
    noUserWaitingForAnActivation: `Aucun utilisateur en attente d'activation`,
    firstname: `Prénom`,
    lastname: `Nom`,
    address: `Adresse`,
    city: `Ville`,
    postalCode: `Code postal`,
    phone: `Téléphone`,
    mobile: `Portable`,
    appTitle: `Gérer Mon LMNP`,
    register: `Créer un compte`,
    refundRate: `Rembousement`,
    editUser: `Modifier l'utilisateur`,
    deleteAccount: `Supprimer l'utilisateur`,
    // deliveryDate: `Date de livraison`,
    manager: `Gestionnaire`,
    siret: `SIRET`,
    taxesCenter: `Centre des impôts`,
    display: 'afficher',
    infoSiret: `Veuillez vous référer à votre avis SIRENE, disponible sur `,
    infoSiretLink: `https://avis-situation-sirene.insee.fr/`,
    projectInfoBeforeCreation: `Informations concernant votre bien : ces informations nous permettront de
    déterminer le mode et le rythme d’amortissement de votre bien.`,
    deleteUserDetails: `Cliquez sur le bouton supprimer pour supprimer totalement et définitivement le compte sélectionné. En supprimant l'utilisateur, toutes les locations et données comptables associées à l'utilisateur seront elles aussi supprimées.`,
    noAddress: `aucune adresse`,
    goToISuite: `accéder à ISuite`,
    filesHandlingInfo: `pour gérer vos fichiers avec efficacité et sécurité, Gérer Mon LMNP vous propose un accès à ISuite. En cliquant sur le bouton ci-dessous, vous aurez accès à l'espace ISuite qui vous aidera dans vos demarches de dépôt ou de récupération de fichiers.`,
    files: `Mes documents`,
    editProject: `Modifier la location`,
    generateResult: `Générer le bilan Excel`,
    accountingTitle: `Flux financiers de votre LMNP`,
    trimester: `trimestre`,
    incomes: `revenus`,
    observations: `observations`,
    datePayment: `date de versement`,
    dateInvoice: `date de facturation`,
    provider: `Fournisseur`,
    nature: `Nature`,
    new: `nouveau`,
    month: `mois`,
    amount: `Montant (€)`,
    capital: `Capital (€)`,
    interest: `Intérêts (€)`,
    insurance: `Assurance (€)`,
    letter: `Lettre de mission`,
    generateLetter: `générer la lettre de mission`,
    displayLetter: `afficher la lettre de mission`,
    createLetter: `Créer ma lettre de mission et activer la location`,
    activate: `activer`,
    create: `créer`,
    beginToUse: `Commencez à utiliser votre espace personnalisé`,
    explainLetter: `Pour commencer à utiliser votre espace personnalisé, remplissez les informations ci-dessous (choix de votre forfait, informations bancaires, etc...), et acceptez les conditions présentées.`,
    yourUseData: `Vos données utilisateur`,
    yourUseDataAdmin: `Données de l'utilisateur`,
    yourMainProjectData: `Votre location principale : {name}`,
    yourOtherProjectData: `Location additionelle n°{i} : {name}`,
    yourProjectData: `Votre location`,
    checkYourData: `Veuillez vérifier la validité des données présentées ci-dessous et modifiez-les en cas de besoin.`,
    additionalProject: `Locations supplémentaires`,
    sendLetter: `Valider et envoyer ma lettre de mission`,
    warningFormNotSubmitted: `Veuillez valider tous les formulaires avant de générer votre lettre`,
    letterOption1: `(100€) Forfait sans adhésion CGA`,
    letterOption2: `(0€) Bien ou lot supplémentaires LMNP`,
    letterOption3: `(0€) En cas d'adhésion CGS, envoi du dossier dematerialisé au CGA (FEC, EPS, ECV, ...)`,
    letterOption4: `(0€) Rendez-vous téléphonique pour le bilan`,
    letterOption5: `(0€) Immatriculation auprès du greffe`,
    downloadEngagementLetter: `Télécharger la lettre de mission`,
    partiallyEnabled: `En cours de validation`,
    fullyEnabled: `Validée`,
    search: `Rechercher`,
    enabled: `actif`,
    disabled: `inactif`,
    enable: `activer`,
    disable: `désactiver`,
    disableUser: `désactiver l'utilisateur`,
    enableUser: `activer l'utilisateur`,
    disableUserDetails: `Cliquez sur le bouton désactiver pour retirer le droit d'accès du compte sélectionné. Vous pouvez ré-activer le compte à tout moment.`,
    enableUserDetails: `Cliquez sur le bouton activer pour redonner le droit d'accès du compte sélectionné.`,
    validateEngagementLetter: `Valider la lettre de mission`,
    bankName: `Nom du débiteur`,
    iban: `Numéro de compte IBAN`,
    bic: `Code BIC`,
    fiscalMandate: `Mandat de représentation fiscale`,
    confirmationNotMatching: `Le mot de passe et sa confirmation ne correspondent pas`,
    passwordInvalid: `Le mot de passe est invalide : au moins 5 caractères sont requis`,
    invalidForm: `Le formulaire est invalide`,
    cgaForm: `Formulaire adhésion CGA`,
    choosePricing: `Fiscalité de votre bien`,
    pricing1: `320€ TTC`,
    pricingDetails1: `Forfait comptabilité de mon LMNP sans adhésion CGA`,
    pricing2: `60€ TTC`,
    pricingDetails2: `Adhésion au CGA. En cas d’adhésion CGA, envoi du dossier dématérialisé au CGA (FEC, EPS, ECV, ...)`,
    pricing3: `Offert`,
    pricingDetails3: `Rendez-vous téléphonique pour le bilan`,
    pricing4: `Offert`,
    pricingDetails4: `Immatriculation auprès du Greffe`,
    pricing5: `60€ TTC`,
    pricingDetails5: `Lot supplémentaire`,
    fiscalMandateFrench: `Déclare par la présente, constituer pour mandataire Le cabinet Gérer Mon LMNP – 36 rue des Jardins 57050 LE BAN SAINT MARTIN, N° SIRET 437 897 309 00013, auquel je donne pouvoir : `,
    fiscalMandateList1: `D’établir, de signer en mon nom et de déposer les
    déclarations fiscales relatives à l’activité de loueur en meublé`,
    fiscalMandateList2: `De recevoir et de répondre à toutes les demandes de l’administration fiscale s’y rapportant et d’effectuer les démarches contentieuses ou gracieuses y afférentes.`,
    fiscalMandateForeign: `Donne mandat à la société Gérer Mon LMNP – 36 rue des Jardins 57050 LE BAN SAINT MARTIN, N° SIRET 437 897 309 00013, pour me représenter fiscalement en France, en application des articles 164 D et 289 AJ du CGI dans le cadre exclusif de la présente location meublée.`,
    fiscalMandateForeignDetail: `Le résultat généré par votre LMNP devra être reporté sur la déclaration de revenu 2042 à adresser au SIP des non résidents. Si vous le souhaitez vous pouvez nous consulter.`,
    frenchResident: `Résident Français`,
    foreignResident: `Résident étranger`,
    fiscalMandate1: `Je soussigné(e) :`,
    fiscalMandate2: `Demeurant à :`,
    fiscalMandate3: `Acquéreur dans la résidence :`,
    fiscalMandate4: `Du lot :`,
    creditor: `Identifiant créancier SEPA (ICS)`,
    debtor: `Identifiant débiteur`,
    creditorName: `Nom du créancier`,
    creditorAddress: `Adresse`,
    creditorCity: `Ville`,
    creditorPostalCode: `Code postal`,
    creditorCountry: `Pays`,
    gererMonLMNPName: `Gérer Mon LMNP`,
    gererMonLMNPAddress: `36 rue des Jardins`,
    gererMonLMNPCity: `Le Ban Saint Martin`,
    gererMonLMNPPostalCode: `57050`,
    gererMonLMNPCountry: `France`,
    numeroAdherent: `Numéro d'adhérent`,
    connaissance: `comment avez-vous connu le cabinet ?`,
    genre: `Genre`,
    nom: `Nom`,
    prenom: `Prénom`,
    dateNaissance: `Date de naissance`,
    dateCreation: `Date de création`,
    activitePrincipale: `Activité principale`,
    codeActivite: `Code d'activité (NAF)`,
    formeJuridique: `Forme juridique`,
    denominationSocial: `Dénomination sociale`,
    adressePro: `Adresse professionnelle`,
    villePro: `Ville`,
    codePostalPro: `Code postal`,
    adresseCorrespondance: `Adresse de correspondance`,
    villeCorrespondance: `Ville`,
    codePostalCorrespondance: `Code postal`,
    transfert: `Je déclare cesser mon adhésion à compter de ce jour`,
    cgaAdmin: `données administrateur`,
    cgaUser: `données utilisateur`,
    cgaCompany: `données entreprise`,
    cgaFiscale: `régime fiscal`,
    cgaTva: `régime TVA`,
    cgaDate: `adhésion`,
    cgaExercice: `Premier exercice à prendre en compte`,
    cgaExerciceDetail: `Renseignez ici la date d’acquisition de votre bien, ou en cas de passage
    au régime meublé, la date de mise en location`,
    cgaTransfert: `Transfert`,
    bankInfoPresentation: `En signant ce formulaire de mandat, vous autorisez Gérer Mon LMNP à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions de Gérer Mon LMNP. Vous bénéficiez du droit d’être remboursé par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de débit de votre compte pour un prélèvement autorisé.`,
    changePricing: `Changer de forfait`,
    passwordConfirmation: `Confirmation du mot de passe`,
    loyerNet: `Loyer €TTC`,
    loyerBrut: `Loyer €HT`,
    loyerTVA: `Loyer €TVA`,
    chargesLocNet: `Charges Loc. €TTC`,
    chargesLocBrut: `Charges Loc. €HT`,
    chargesLocTVA: `Charges Loc. €TVA`,
    restore: `Restaurer`,
    fournisseurs: `Fournisseurs`,
    ttc: `TTC (€)`,
    ht: `HT (€)`,
    tva: `TVA (€)`,
    letterTitle: `Lettre de mission`,
    show: 'afficher',
    legalTextAccounting: `Liste des éléments minimum équipant le meublé : Décret du 31 juillet 2015`,
    legalTextAccounting1: `Art. 2 : Le mobilier d'un logement meublé comporte au minimum les éléments suivants :`,
    legalTextAccounting2: `1° Literie comprenant couette ou couverture ;`,
    legalTextAccounting3: `2° Dispositif d'occultation des fenêtres dans les pièces destinées a être utilisées comme chambres a coucher ;`,
    legalTextAccounting4: `3° Plaques de cuisson ;`,
    legalTextAccounting5: `4° Four ou four a micro-ondes ;`,
    legalTextAccounting6: `5° Réfrigerateur et congélateur ou, au minimum, un réfrigerateur dote d'un compartiment permettant de disposer d'une temperature inférieure ou égale a - 6 °C ;`,
    legalTextAccounting7: `6° Vaisselle nécessaire a la prise des repas ;`,
    legalTextAccounting8: `7° Ustensiles de cuisine ;`,
    legalTextAccounting9: `8° Table et sieges ;`,
    legalTextAccounting10: `9° Etageres de rangement ;`,
    legalTextAccounting11: `10° Luminaires ;`,
    legalTextAccounting12: `11° Materiel d'entretien menager adapté aux caracteristiques du logement.`,
    legalTextAccounting13: `Notre mission ne comporte pas le contrôle de la matérialité des opérations. L'analyse juridique et fiscale de votre investissement ne relève pas non plus de cette mission.`,
    gender: `Genre`,
    birthdate: `Date de naissance`,
    editLetter: `Modifier la lettre de mission`,
    validateLetter: `Valider la lettre de mission`,
    changeMyPassword: `Modifier mon mot de passe`,
    currentPassword: `Mot de passe actuel`,
    newPassword: `Nouveau mot de passe`,
    newPasswordConf: `Confirmation nouveau mot de passe`,
    projectName: `Nom de la location`,
    backToMenu: `Retour au menu`,
    nextYear: `Année suivante`,
    previousYear: `Année précédente`,
    year: `Année`,
    addNewYear: `Ajouter une nouvelle année`,
    ViewHelp: {
      text1: `Vous éprouvez des difficultés à comprendre certains concepts ou à
        renseigner certaines informations ?`,
      text2: `Vous pouvez effectuer une demande d'aide à Gérer Mon LMNP. Une fois votre
        demande déposée, un de nos experts vous contactera pour vous aider dans
        vos démarches.`,
      text3: `Vous pouvez, si vous le souhaitez, préciser votre demande dans le champ
        ci-dessous.`,
      sendHelp: `Envoyer ma demande d'aide`,
    },
    ViewHome: {
      noProjectWaiting: `Aucun dossier en attente de validation ou de signature`,
      projectWaiting: `Dossiers en attente de validation ou de signature`,
      accountDisabled: `Votre compte est désactivé pour le moment. Contactez un administrateur
      pour en savoir plus.`,
      letterGenerated: `Votre lettre de mission vient d'être générée. Nous vous invitons à
      relire les informations qui la composent. Vous pouvez encore modifier
      votre lettre de mission en cliquant sur le bouton "Modifier la lettre
      de mission". Une fois toutes les données contrôlées, vous pouvez
      valider la lettre de mission pour qu'elle soit examinée par un de nos
      experts.`,
    },
    Tarification: {
      defaultLibelleBase: `Forfait sans adhésion CGA`,
      defaultLibelleCGA: `En cas d'adhésion CGA, envoi du dossier dématérialisé au CGA (FEC, EPS, ECV, ...)`,
      defaultLibelleTVA: `Soumis à la TVA`,
      defaultLibelleLocation: `Bien ou lot supplémentaire LMNP`,
      defaultLibelleImmatriculation: `Immatriculation initiale et déclaration d'activité et adhésion CGA (le cas échéant)`,
      defaultLibelleFixe: `À la création de votre activité et à chaque acquisition supplémentaire`,
      libelleBase: `Libelle de base`,
      tarifBase: `Tarif de base`,
      libelleCGA: `Libelle CGA`,
      tarifCGA: `Tarif CGA`,
      libelleTVA: `Libelle TVA`,
      tarifTVA: `Tarif TVA`,
      libelleLocation: `Libelle location supplémentaire`,
      tarifLocation: `Tarif location supplémentaire`,
      libelleImmatriculation: `Libelle immatriculation`,
      tarifImmatriculation: `Tarif immatriculation`,
      libelleFixe: `Libelle fixe`,
      tarifFixe: `Tarif fixe`,
      startDate: `Date du premier exercice`,
    },
  },
}

export default messages
