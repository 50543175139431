import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './fr'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages,
})

const loadedLanguages = ['fr']

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  document.querySelector('html')?.setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang: string) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(`@/locales/${lang}.ts`).then(messages => {
    i18n.setLocaleMessage(lang, messages.default[lang])
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n
