import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Main from '../views/Main.vue'
import store from '@/store'
import locale from '../locales/index'

Vue.use(VueRouter)

const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1)
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Main,
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('home')} - ${locale.t('appTitle')}`,
      ),
    },
    children: [
      {
        path: '',
        redirect: 'Home',
      },
      {
        name: 'Home',
        path: 'home',
        component: () => import('../views/home/ViewHome.vue'),
      },
      {
        name: 'Help',
        path: 'help',
        component: () => import('../views/home/ViewHelp.vue'),
      },
      {
        name: 'Tutorial',
        path: 'tutorial',
        component: () => import('../views/home/ViewTutorial.vue'),
      },
      {
        name: 'Account',
        path: 'account',
        component: () => import('../views/auth/ViewMyAccount.vue'),
      },
      {
        name: 'User',
        path: 'user/:id',
        component: () => import('../views/user/ViewUser.vue'),
      },
      {
        name: 'Accounts',
        path: 'accounts',
        component: () => import('../views/user/ViewUsers.vue'),
      },
      {
        name: 'Projects',
        path: 'projects',
        component: () => import('../views/project/ViewProjects.vue'),
      },
      {
        name: 'Project',
        path: 'project/:id',
        component: () => import('../views/project/ViewProject.vue'),
      },
      {
        name: 'NewProject',
        path: 'new-project',
        component: () => import('../views/project/ViewNewProject.vue'),
      },
      {
        path: '/files',
        name: 'Files',
        component: () => import('../views/file/ViewFiles.vue'),
      },
      {
        path: '/files/(.*)*',
        name: 'FilesC',
        component: () => import('../views/file/ViewFiles.vue'),
      },
      {
        name: 'Accounting',
        path: 'project/:id/accounting',
        component: () => import('../views/project/ViewAccountings.vue'),
      },
      {
        name: 'AccountingYear',
        path: 'project/:id/accounting/:year',
        component: () => import('../views/project/ViewAccounting.vue'),
      },
      {
        name: 'Letter',
        path: 'project/:id/letter',
        component: () => import('../views/project/ViewLetter.vue'),
      },
      // {
      //   name: 'Signing',
      //   path: 'project/:id/sign-letter',
      //   component: () => import('../views/project/ViewSignLetter.vue'),
      // },
      {
        path: '/superadmin',
        component: () => import('../views/auth/ViewSuperadmin.vue'),
      },
    ],
  },
  {
    path: '/user-tutorial',
    name: 'UserTutorial',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('tutorial')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/auth/ViewUserTutorial.vue'),
  },
  {
    path: '/simulator',
    name: 'Simulator',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('simulator')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/simulator/ViewSimulator.vue'),
  },
  {
    path: '/registration',
    name: 'Registration',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('registration')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/regitration/ViewRegisterForm.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('login')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/auth/ViewLogin.vue'),
  },
  {
    path: '/ask-reset-password',
    name: 'AskResetPassword',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('askResetPassword')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/auth/ViewAskResetPassword.vue'),
  },
  {
    path: '/reset-password',
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('resetPassword')} - ${locale.t('appTitle')}`,
      ),
    },
    component: () => import('../views/auth/ViewResetPassword.vue'),
  },
  {
    meta: {
      title: capitalizeFirstLetter(
        `${locale.t('activateAccount')} - ${locale.t('appTitle')}`,
      ),
    },
    path: '/activate-account',
    component: () => import('../views/auth/ViewActivateAccount.vue'),
  },
  {
    path: '/isuite',
    name: 'ISuite',
    beforeEnter() {
      window.open(
        'https://isuitecsfetassocies.coaxis.com/iSuiteExpert/Connexion?Cnx=CNXDTG',
        '_blank',
      )
    },
  },
  {
    path: '/register',
    name: 'Register',
    beforeEnter() {
      window.open(
        'https://www.gerer-mon-lmnp.fr/gerer-la-comptabilite-de-mon-logement-meuble/',
        '_blank',
      )
    },
  },
  { path: '*', component: Main },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  store.dispatch('nav/refresh', to.fullPath)

  if (to.query.token) {
    store.dispatch('logWithToken', to.query.token)
  }

  if (
    to.name !== 'Login' &&
    to.name !== 'CreateNewAccount' &&
    to.name !== 'AskResetPassword' &&
    to.name !== 'Register' &&
    !to.path.match('/reset-password') &&
    to.path !== '/simulator' &&
    to.path !== '/simulator/' &&
    to.path !== '/registration' &&
    to.path !== '/registration/' &&
    to.path !== '/user-tutorial' &&
    to.path !== '/user-tutorial/' &&
    !store.getters.isLogged
  ) {
    next({ name: 'Login', params: { backurl: to.fullPath } })
  } else {
    next()
  }
})

export default router
