import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { SideNavItem } from '@/model/SideNavItem'
import { ROLES } from '@/model/Role'
import locale from '../locales/index'
import { LETTER_STATES, Project, PROJECT_STATES } from '@/model/Project'
import { User, USER_STATES } from '@/model/User'

class State {
  toggled = false
  isMobile = false
  navItems: SideNavItem[] = []
  projects: Project[] = []

  generate(route: string, user: User, projects: Project[] = []): SideNavItem[] {
    const res: SideNavItem[] = []
    if (!route) {
      route = ''
    }

    res.push({
      name: locale.t('home').toString(),
      to: '/home',
      isTitle: true,
      isActive: !!route.match('home'),
    })

    res.push({
      name: locale.t('tutorial').toString(),
      to: '/tutorial',
      isTitle: true,
      isActive: !!route.match('tutorial'),
    })

    if (user.state === USER_STATES.DISABLED) {
      return res
    }

    if (user.role === ROLES.USER) {
      res.push({
        name: locale.t('myProjects').toString(),
        to: '/projects',
        isTitle: true,
        isActive: !!route.match('projects'),
      })

      let atLeastOneEnabled = false

      const mainProject = projects.find(p => p.main)

      for (const p of projects) {
        if (p && p.name) {
          if (p.state === PROJECT_STATES.ENABLED) {
            atLeastOneEnabled = true
          }

          let notification = 0

          if (p.main) {
            if (
              p.letterState === LETTER_STATES.NOT_CREATED ||
              p.letterState === LETTER_STATES.CREATED
            ) {
              notification = 1
            }

            res.push({
              name: p.name || '',
              to: `/project/${p.id}`,
              isTitle: false,
              isActive: !!route.match(`project/${p.id}`),
              notification,
            })

            if (mainProject?.letterState === LETTER_STATES.COMPLETED) {
              if (route.match(`project/${p.id}`)) {
                res.push({
                  name: locale.t('engagementLetter').toString(),
                  to: `/project/${p.id}/letter`,
                  isTitle: false,
                  depth: 3,
                })

                res.push({
                  name: locale.t('accounting').toString(),
                  to: `/project/${p.id}/accounting`,
                  isTitle: false,
                  depth: 3,
                })
              }
            }
          }

          if (!p.main && mainProject?.letterState === LETTER_STATES.COMPLETED) {
            res.push({
              name: p.name || '',
              to: `/project/${p.id}`,
              isTitle: false,
              isActive: !!route.match(`project/${p.id}`),
              notification,
            })

            if (route.match(`project/${p.id}`)) {
              res.push({
                name: locale.t('engagementLetter').toString(),
                to: `/project/${p.id}/letter`,
                isTitle: false,
                depth: 3,
              })

              res.push({
                name: locale.t('accounting').toString(),
                to: `/project/${p.id}/accounting`,
                isTitle: false,
                depth: 3,
              })
            }
          }
        }
      }

      if (atLeastOneEnabled) {
        res.push({
          name: locale.t('newProject').toString(),
          to: '/new-project',
          isTitle: false,
          isActive: !!route.match(`new-project`),
        })
      }

      res.push({
        name: locale.t('files').toString(),
        to: '/files',
        isTitle: true,
        isActive: !!route.match('files'),
      })
    }

    if (user.role === ROLES.SUPERADMIN || user.role === ROLES.ADMIN) {
      res.push({
        name: locale.t('users').toString(),
        to: '/accounts',
        isTitle: true,
        isActive: !!route.match('accounts'),
      })

      res.push({
        name: locale.t('files').toString(),
        to: '/files',
        isTitle: true,
        isActive: !!route.match('files'),
      })
    }

    return res
  }
}

const state = new State()

export type navType = {}

const getters: GetterTree<State, navType> = {
  toggled: state => state.toggled,
  isMobile: state => state.isMobile,
  sideNavItems: state => state.navItems,
}

const actions: ActionTree<State, navType> = {
  refresh({ commit, rootGetters, state }, route: string) {
    const user = rootGetters.loggedUser
    const projects = rootGetters.projects
    const navItems = state.generate(route, user, projects)
    commit('refresh', navItems)
  },
  toggle({ commit }) {
    commit('toggle')
  },
  setMobile({ commit }) {
    commit('close')
    commit('setMobile', true)
  },
  setDesktop({ commit }) {
    commit('open')
    commit('setMobile', false)
  },
  close({ commit }) {
    commit('close')
  },
}

const mutations: MutationTree<State> = {
  refresh(state, navItems) {
    state.navItems = navItems
  },
  toggle(state) {
    state.toggled = !state.toggled
  },
  close(state) {
    state.toggled = false
  },
  open(state) {
    state.toggled = true
  },
  setMobile(state, isMobile: boolean) {
    state.isMobile = isMobile
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
