









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class BaseToggleButton extends Vue {
  @Prop({ default: false }) readonly toggled!: boolean

  get _toggled(): boolean {
    return this.toggled
  }

  set _toggled(toggled: boolean) {
    this.$emit('toggled', toggled)
  }

  toggle(toggled: boolean) {
    this._toggled = toggled
  }
}
