










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseButton extends Vue {
  @Prop() private disabled!: boolean

  click() {
    this.$emit('click')
  }
}
