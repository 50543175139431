












































import { Component, Prop, Vue } from 'vue-property-decorator'

export interface Tab {
  id: number
  label: string
}

@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: [] }) tabs!: Tab[]

  activeTabId = -1
  lastActive = -1

  get left() {
    return this.activeTabId < this.lastActive
  }

  get right() {
    return this.activeTabId > this.lastActive
  }

  setTab(id: number) {
    if (this.activeTabId === id) return
    this.lastActive = this.activeTabId
    this.activeTabId = id
  }

  created() {
    if (this.tabs && this.tabs.length) {
      this.setTab(this.tabs[0].id)
    }
  }
}
