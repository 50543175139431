







import { Component, Vue } from 'vue-property-decorator'
import TheNav from '@/components/navigation/TheNav.vue'
import TheView from '@/components/navigation/TheView.vue'

@Component({
  components: { TheNav, TheView },
})
export default class Main extends Vue { }
