


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class BaseCollapsible extends Vue {
  @Prop({ default: 'expand' }) labelExpand!: string
  @Prop({ default: 'collapse' }) labelCollapse!: string

  toggled = false

  toggle(toggled: boolean) {
    this.toggled = toggled

    const content = this.$el.querySelector(
      '.collapsible-content',
    ) as HTMLElement

    if (this.toggled) {
      this.expand(content)
    } else {
      this.collapse(content)
    }
  }

  collapse(element: HTMLElement) {
    requestAnimationFrame(() => {
      this.$emit('collapsed')
      element.style.height = 0 + 'px'
    })
  }

  expand(element: HTMLElement) {
    requestAnimationFrame(() => {
      this.$emit('expanded')
      element.style.height = 0 + 'px'
      element.style.height = element.scrollHeight + 'px'
    })
  }
}
