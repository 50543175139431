import { AccountingExpenses } from './AccountingExpenses'
import { AccountingIncomes } from './AccountingIncomes'
import { AccountingLoans } from './AccountingLoans'

export enum ACCOUNTING_STATES {
  LOCKED = 'locked',
  ENABLED = 'enabled',
}

export enum ACCOUNTING_GESTION {
  DIRECT = '1',
  RESIDENCE_SERVICE = '2',
}

export interface AccountingFinancial {
  id?: number
  year?: number
  capital?: number
  projectId?: number
  state?: ACCOUNTING_STATES
  gestion?: string
  personalUsage?: number
  expenses: AccountingExpenses[]
  incomes: AccountingIncomes[]
  loans: AccountingLoans[]
  datesPreBilan?: string[]
}
