import { render, staticRenderFns } from "./BaseFileInput.vue?vue&type=template&id=a95ba276&scoped=true&"
import script from "./BaseFileInput.vue?vue&type=script&lang=ts&"
export * from "./BaseFileInput.vue?vue&type=script&lang=ts&"
import style0 from "./BaseFileInput.vue?vue&type=style&index=0&id=a95ba276&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a95ba276",
  null
  
)

export default component.exports