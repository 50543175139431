import Vue from 'vue'
import Vuex from 'vuex'
import { state, getters, actions, mutations } from './store'
import toaster from './toaster'
import locales from './locales'
import nav from './nav'

Vue.use(Vuex)

export default new Vuex.Store({
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {
    toaster,
    locales,
    nav,
  },
})
