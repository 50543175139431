













import { Component, Prop, Vue } from 'vue-property-decorator'

export interface CustomFile {
  base64: string
  extention: string
}

@Component({})
export default class BaseFileInput extends Vue {
  @Prop() private id!: string

  fileReader = new FileReader()
  extention = ''

  constructor() {
    super()
    this.fileReader.addEventListener('load', this.emitFile)
  }

  emitFile(event: ProgressEvent<FileReader>) {
    this.$emit('input', {
      base64: event.target?.result,
      extention: this.extention,
    } as CustomFile)
  }

  getFile(files: File[]) {
    const file = files[0]

    this.extention = file.name.split('.').pop() || ''
    this.fileReader.readAsDataURL(file)
  }

  hasFocus = false
}
