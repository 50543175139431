












import { Component, Vue } from 'vue-property-decorator'
import TheSideNav from '@/components/navigation/TheSideNav.vue'
import TheFooter from '@/components/navigation/TheFooter.vue'

@Component({
  components: { TheSideNav, TheFooter },
})
export default class TheView extends Vue {
  get toggled(): boolean {
    return this.$store.getters['nav/toggled']
  }

  constructor() {
    super()
    const mediaQuery = window.matchMedia('(max-width: 600px)')
    this.toggleOnResize(mediaQuery)
    mediaQuery.addListener(this.toggleOnResize)
  }

  toggleOnResize(mediaQuery: MediaQueryList | MediaQueryListEvent) {
    if (mediaQuery.matches) {
      this.$store.dispatch('nav/setMobile')
    } else {
      this.$store.dispatch('nav/setDesktop')
    }
  }

  closeNav() {
    if (this.$store.getters['nav/isMobile']) {
      this.$store.dispatch('nav/close')
    }
  }
}
