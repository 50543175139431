

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TheSideNavItem extends Vue {
  @Prop({ default: '' }) to!: string
  @Prop() isTitle!: boolean
  @Prop() isActive!: boolean
  @Prop() depth!: number
  @Prop() notification!: number

  get toggled() {
    return this.$store.getters['nav/toggled']
  }

  get isClickable(): boolean {
    return !!this.to
  }

  get isDepth(): boolean {
    return !!this.depth
  }
}
