import store from '../store/index'

export interface ServerResponse {
  code: number
  data?: any
  error?: any
  success?: boolean
  type?: string
  message?: string
  status?: number
}

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

class ApiClient {
  baseUrl = process.env.VUE_APP_API_URL

  get token(): string {
    const token = localStorage.getItem('token')
    if (!token || token === 'undefined') {
      return ''
    }
    return token
  }

  lang = 'fr'

  headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-HTTP_METHODS': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': this.lang,
  }

  async get(path: string) {
    const request = {
      method: HTTP_METHODS.GET,
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, HTTP_METHODS.GET)
        if (json.code === 'token_expired') {
          store.dispatch('logout')
        }
        return json
      })
      .catch(error => {
        this.handleServerError(error)
        return error
      })
  }

  async post(path: string, body: {}) {
    const request = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, HTTP_METHODS.POST)
        if (json.code === 'token_expired') {
          store.dispatch('logout')
        }
        return json
      })
      .catch(error => {
        this.handleServerError(error)
        return { type: 'error', message: error.message }
      })
  }

  async put(path: string, body: {}) {
    const request = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, HTTP_METHODS.PUT)
        if (json.code === 'token_expired') {
          store.dispatch('logout')
        }
        return json
      })
      .catch(error => {
        this.handleServerError(error)
        return error
      })
  }

  async delete(path: string, body: {} = {}) {
    const request = {
      method: HTTP_METHODS.DELETE,
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async response => {
        const json = await response.json()
        this.logResponse(json, path, HTTP_METHODS.DELETE)
        if (json.code === 'token_expired') {
          store.dispatch('logout')
        }
        return json
      })
      .catch(error => {
        this.handleServerError(error)
        return error
      })
  }

  logResponse(json: any, url: string, method: string) {
    if (process.env.VUE_APP_MODE === 'dev') {
      console.log(`Server call on ${method} ${url} and response with : `, json)
    }
  }

  handleServerError(error: Response) {
    if (process.env.VUE_APP_MODE === 'dev') {
      console.log('Une erreur est survenue sur le serveur', error)
    }
  }
}

export default new ApiClient()
