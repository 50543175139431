export enum PROJECT_STATES {
  DISABLED = 'disabled',
  WAITING = 'waiting',
  ENABLED = 'enabled',
}

export enum LETTER_STATES {
  NOT_CREATED = 'not_created',
  CREATED = 'created',
  USER_VALIDATED = 'user_validated',
  ADMIN_VALIDATED = 'admin_validated',
  COMPLETED = 'completed',
}

export enum TERRAINS {
  CAMPAGNE = '1',
  VILLE = '2',
  GRANDE_VILLE = '3',
  PARIS_INTRA = '4',
}

export enum LOCATION_ETATS {
  TRES_BON = '1',
  BON = '2',
  VETUSTE = '3',
}

export enum FORMES_JURIDIQUES {
  ENTREPRISE_INDIVIDUELLE = '1',
  SARL_FAMILLE = '5',
  AUTRE = 'Autre',
}

export enum REGIMES_FISCAL {
  BIC = '1',
  BA = '2',
  IS = '3',
  MICRO_BIC = '4',
}

export enum REGIMES_FISCAL_RNRS {
  REEL_NORMAL = '1',
  REEL_SIMPLIFIE = '2',
}

export enum REGIMES_FISCAL_DE_DROIT {
  REGIME_1 = '1',
}

export enum REGIMES_TVA_DECLARATION {
  TRIMESTRIEL = '2',
  ANNUEL = '3',
}

export interface Project {
  id?: number
  name?: string
  address?: string
  city?: string
  postalCode?: string
  manager?: string
  siret?: string
  taxesCenter?: string
  state?: PROJECT_STATES
  letterState?: LETTER_STATES
  image?: string
  terrain?: string
  grosoeuvre?: string
  facade?: string
  agencement?: string
  tva?: boolean | number
  main?: boolean
  libelleBase?: string
  tarifBase?: number
  libelleCGA?: string
  tarifCGA?: number
  libelleTVA?: string
  tarifTVA?: number
  libelleLocation?: string
  tarifLocation?: number
  libelleImmatriculation?: string
  tarifImmatriculation?: number
  libelleFixe?: string
  tarifFixe?: number
  startDate?: string
}

export interface FiscalMandate {
  lastname?: string
  firstname?: string
  address?: string
  postalCode?: string
  city?: string
  etranger?: boolean
}

export interface BankDetails {
  name?: string
  address?: string
  city?: string
  postalCode?: string
  iban?: string
  bic?: string
}

export interface Cga {
  id?: number
  numeroAdherent?: string
  date?: string
  connaissance?: string
  genre?: string
  nom?: string
  prenom?: string
  dateNaissance?: string
  siret?: string
  dateCreation?: string
  activitePrincipale?: string
  codeActivite?: string
  mobile?: string
  email?: string
  formeJuridique?: string
  denominationSocial?: string
  adressePro?: string
  villePro?: string
  codePostalPro?: string
  adresseCorrespondance?: string
  villeCorrespondance?: string
  codePostalCorrespondance?: string
  regimeFiscal?: string
  regimeFiscalRnRs?: string
  regimeFiscalDeDroit?: string
  regimeTva?: string
  regimeTvaDeclaration?: string
  regimeTvaTransmission?: string
  dateDebutExercice?: string
  dateFinExercice?: string
  premiereAdhesion?: boolean
  premiereAdhesionNom?: string
  transfert?: boolean
  nomTransfert?: string
  adresseTransfert?: string
  villeTransfert?: string
  codePostalTransfert?: string
}

export interface KeyLabel {
  key: any
  label: any
}
