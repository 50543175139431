






















import { Component, Vue } from 'vue-property-decorator'
import TheSideNavItem from '@/components/navigation/TheSideNavItem.vue'
import { SideNavItem } from '@/model/SideNavItem'
import Utils from '../../utils/Utils'

@Component({
  components: {
    TheSideNavItem,
  },
})
export default class TheSideNav extends Vue {
  getName(name: string): string {
    return Utils.toFirstUpperCase(name)
  }

  get toggled() {
    return this.$store.getters['nav/toggled']
  }

  closeIfMobile() {
    if (this.$store.getters['nav/isMobile']) {
      this.$store.dispatch('nav/close')
    }
  }

  get navItems(): SideNavItem[] {
    return this.$store.getters['nav/sideNavItems']
  }
}
