























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseCheckbox extends Vue {
  @Prop() value!: boolean

  rand: number = Math.random()
  hasFocus: boolean = false

  get uniqueId(): string {
    return `${this.rand}`
  }

  toggle() {
    this.$emit('input', !this.value)
  }
}
