






import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class TheFooter extends Vue {
  get toggled() {
    return this.$store.getters['nav/toggled']
  }
}
