import { ROLES } from './Role'

export enum USER_STATES {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum GENDERS {
  MALE = '1',
  FEMALE = '2',
}

export enum PERSONNES {
  PHYSIQUE = '1',
  MORALE = '2',
}

export interface User {
  id?: number
  role?: ROLES
  username?: string
  email?: string
  gender?: string
  birthdate?: string
  password?: string
  activated?: boolean
  firstname?: string
  lastname?: string
  address?: string
  city?: string
  postalCode?: string
  mobile?: string
  projectIds?: number[]
  state?: USER_STATES
  image?: string
  cga?: boolean | number
  creation?: boolean | number
  personne?: PERSONNES
  denominationSociale?: string
  siret?: string
}
