








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class BaseBottomButton extends Vue {
  @Prop() disabled!: boolean

  validated() {
    this.$emit('validated')
  }
}
