import { GetterTree, MutationTree, ActionTree } from 'vuex'

export interface Toast {
  id: number
  message: string
  type: string
}

class State {
  toasts: Toast[] = []
  currentId = 0
  toastTime = 5000
  wptoasts: Toast[] = []
  wpcurrentId = 0
  wptoastTime = 5000
}

const state = new State()

export type toasterType = {}

const getters: GetterTree<State, toasterType> = {
  toasts: state => state.toasts,
  currentId: state => state.currentId,
  toastTime: state => state.toastTime,
  wptoasts: state => state.wptoasts,
  wpcurrentId: state => state.wpcurrentId,
  wptoastTime: state => state.wptoastTime,
}

const actions: ActionTree<State, toasterType> = {
  toast({ commit, getters }, toast: Toast) {
    const id = getters.currentId
    commit('pushToast', { ...toast, id })
    setTimeout(() => {
      commit('removeToast', id)
    }, getters.toastTime)
    commit('incrementId')
  },
  remove({ commit }, toast: Toast) {
    commit('removeToast', toast.id)
  },
  wptoast({ commit, getters }, toast: Toast) {
    const id = getters.wpcurrentId
    commit('wppushToast', { ...toast, id })
    setTimeout(() => {
      commit('wpremoveToast', id)
    }, getters.wptoastTime)
    commit('wpincrementId')
  },
  wpremove({ commit }, toast: Toast) {
    commit('wpremoveToast', toast.id)
  },
}

const mutations: MutationTree<State> = {
  pushToast(state, toast: Toast) {
    state.toasts.push(toast)
  },
  removeToast(state, id: number) {
    state.toasts = state.toasts.filter(t => t.id !== id)
  },
  incrementId(state) {
    state.currentId++
  },
  wppushToast(state, toast: Toast) {
    state.wptoasts.push(toast)
  },
  wpremoveToast(state, id: number) {
    state.wptoasts = state.wptoasts.filter(t => t.id !== id)
  },
  wpincrementId(state) {
    state.wpcurrentId++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
