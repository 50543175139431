import { GetterTree, MutationTree, ActionTree } from 'vuex'
import DashboardApi, { ServerResponse } from '@/api/DashboardApi'
import {
  BankDetails,
  Cga,
  FiscalMandate,
  LETTER_STATES,
  Project,
  PROJECT_STATES,
} from '@/model/Project'
import {
  AccountingFinancial,
  ACCOUNTING_STATES,
} from '@/model/AccountingFinancial'
import { AccountingIncomes } from '@/model/AccountingIncomes'
import { AccountingExpenses } from '@/model/AccountingExpenses'
import { AccountingLoans } from '@/model/AccountingLoans'
import { User, USER_STATES } from '@/model/User'
import { TokenData } from '@/model/TokenData'
import { AccountingCautions } from '@/model/AccountingCautions'
import { ROLES } from '@/model/Role'

const DEFAULT_USER: User = {}
const DEFAULT_TOKEN_DATA: TokenData = { user: DEFAULT_USER, exp: 0 }
const DEFAULT_PROJECT: Project = {}
const DEFAULT_ACCOUNTING: AccountingFinancial = {
  incomes: [],
  expenses: [],
  loans: [],
}

class State {
  token: string = localStorage.getItem('token') || ''
  tokendata: TokenData = DEFAULT_TOKEN_DATA
  sessionExpiration: number = JSON.parse(
    localStorage.getItem('sessionExpiration') || JSON.stringify(0),
  )
  loggedUser: User = JSON.parse(
    localStorage.getItem('loggedUser') || JSON.stringify(DEFAULT_USER),
  )
  user: User = DEFAULT_USER
  users: User[] = []
  project: Project = DEFAULT_PROJECT
  projects: Project[] = []
  accounting: AccountingFinancial = DEFAULT_ACCOUNTING
  accountings: AccountingFinancial[] = []
  expenses: AccountingExpenses[] = []
  loans: AccountingLoans[] = []
  incomes: AccountingIncomes[] = []
  cautions: AccountingCautions[] = []
  viewUsersPage = 0
  cga: Cga = {}
  bankDetails: BankDetails = {}
  fiscalMandate: FiscalMandate = {}
  projectImage = ''
  letter = ''
  tva = false
  tuto = ''
}

const state = new State()

const getters: GetterTree<State, any> = {
  token: (state): string => state.token,
  tokendata: (state): TokenData => state.tokendata,
  loggedUser: (state): User => state.loggedUser || DEFAULT_USER,
  isLogged: (state): boolean =>
    new Date().getTime() < state.sessionExpiration * 1000,
  isAdmin: (state): boolean =>
    state.loggedUser.role === ROLES.SUPERADMIN ||
    state.loggedUser.role === ROLES.ADMIN,
  user: (state): User => state.user || DEFAULT_USER,
  users: (state): User[] => state.users,
  project: (state): Project => state.project || DEFAULT_PROJECT,
  projects: (state): Project[] => state.projects,
  accounting: (state): AccountingFinancial =>
    state.accounting || DEFAULT_ACCOUNTING,
  accountings: (state): AccountingFinancial[] => state.accountings,
  expenses: (state): AccountingExpenses[] => state.expenses,
  loans: (state): AccountingLoans[] => state.loans,
  incomes: (state): AccountingIncomes[] => state.incomes,
  cautions: (state): AccountingCautions[] => state.cautions,
  viewUsersPage: (state): number => state.viewUsersPage,
  cga: (state): Cga => state.cga,
  bankDetails: (state): BankDetails => state.bankDetails,
  fiscalMandate: (state): FiscalMandate => state.fiscalMandate,
  projectImage: (state): string => state.projectImage,
  letter: (state): string => state.letter,
  tva: (state): boolean => state.tva,
  tuto: (state): string => state.tuto,
  mainProject: (state): Project => state.projects.find(p => p.main) as Project,
}

const actions: ActionTree<State, any> = {
  init({ dispatch }) {
    dispatch('getUsers')
    dispatch('getProjects')
  },
  // Auth
  async login(
    { commit, state, dispatch },
    { username, password },
  ): Promise<ServerResponse> {
    return DashboardApi.post('users/login', { username, password }).then(
      json => {
        if (json.success) {
          commit('SET_TOKEN', json.data)
          commit('SET_TOKEN_DATA', state.token)
          commit('SET_SESSION_EXPIRATION', state.tokendata.exp)

          return dispatch('getUser', state.tokendata.user?.id).then(json2 => {
            commit('SET_LOGGED_USER', json2.data || DEFAULT_USER)
            return json
          })
        } else {
          return json
        }
      },
    )
  },
  async logWithToken({ commit, state, dispatch }, token) {
    commit('SET_TOKEN', token)
    commit('SET_TOKEN_DATA', state.token)
    commit('SET_SESSION_EXPIRATION', state.tokendata.exp)

    dispatch('getUser', state.tokendata.user?.id).then(json => {
      commit('SET_LOGGED_USER', json.data || DEFAULT_USER)
    })
  },
  async askActivation({ state }, userId): Promise<ServerResponse> {
    return DashboardApi.post(`users/${userId}/ask-activation`, {})
  },
  async activateAccount(
    { commit },
    { userId, password },
  ): Promise<ServerResponse> {
    return DashboardApi.post(`users/${userId}/activate-account`, {
      password,
    }).then(json => {
      if (json.success) {
        commit('SET_LOGGED_USER', json.data)
      }
      return json
    })
  },
  logout({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_SESSION_EXPIRATION', 0)
    commit('SET_LOGGED_USER', DEFAULT_USER)
  },
  async resetPassword({ state }, password): Promise<ServerResponse> {
    return DashboardApi.post(`users/${state.loggedUser.id}/reset-password`, {
      password,
    })
  },
  async askResetPassword(state, username): Promise<ServerResponse> {
    return DashboardApi.post(`users/ask-reset-password`, { username })
  },
  async askCreateiSuite(): Promise<ServerResponse> {
    return DashboardApi.post(`users/ask-create-isuite`, {})
  },
  async updateOwnData({ commit, state }, { user }): Promise<ServerResponse> {
    return DashboardApi.put(`users/${state.loggedUser.id}`, user).then(json => {
      if (json.success) {
        commit('UPDATE_LOGGED_USER', json.data)
      }
      return json
    })
  },
  async preRegister({ commit }, data: any): Promise<ServerResponse> {
    // const data = {
    //   sonnom: user.lastname,
    //   sonprenom: user.firstname,
    //   sonmail: user.email,
    //   bienadresse: project.address,
    //   bienville: project.city,
    //   biencodepostal: project.postalCode,
    // }
    return DashboardApi.post(`users/pre-register`, data).then(json => {
      // if (json.success) {
      //   commit('UPDATE_LOGGED_USER', json.data)
      // }
      return json
    })
  },
  // Users
  async getUsers({ commit }, searchdata: any = {}): Promise<ServerResponse> {
    return DashboardApi.post(`users/search`, searchdata).then(json => {
      if (json.success) {
        commit('SET_USERS', json.data)
      }
      return json
    })
  },
  async getUser({ commit }, id: number): Promise<ServerResponse> {
    return DashboardApi.get(`users/${id}`).then(json => {
      if (json.success) {
        commit('SET_USER', json.data)
      }
      return json
    })
  },
  async addUser({ commit }, user: User): Promise<ServerResponse> {
    return DashboardApi.post(`users/register`, user).then(json => {
      if (json.success) {
        commit('ADD_USER', json.data)
      }
      return json
    })
  },
  async updateUser({ commit }, { user, id }): Promise<ServerResponse> {
    return DashboardApi.put(`users/${id}`, user).then(json => {
      if (json.success) {
        commit('UPDATE_USER', json.data)
      }
      return json
    })
  },
  async deleteUser({ commit }, id): Promise<ServerResponse> {
    return DashboardApi.delete(`users/${id}`).then(json => {
      if (json.success) {
        commit('DELETE_USER', id)
      }
      return json
    })
  },
  async deleteOwnAccount({ commit, state }): Promise<ServerResponse> {
    return DashboardApi.delete(`users/${state.loggedUser.id}`).then(json => {
      if (json.success) {
        commit('DELETE_USER', state.loggedUser.id)
      }
      return json
    })
  },
  async changePassword(
    { state },
    { currentPassword, newPassword },
  ): Promise<ServerResponse> {
    return DashboardApi.put(`users/${state.loggedUser.id}/password`, {
      currentPassword,
      newPassword,
    }).then(json => {
      return json
    })
  },
  async addUserImage(
    { commit },
    { base64, userId, extention },
  ): Promise<ServerResponse> {
    return DashboardApi.post(`users/${userId}/image`, {
      base64,
      extention,
    }).then(json => {
      if (json.success) {
        commit('SAVE_IMAGE_USER', { id: userId, image: json.data })
      } else {
        commit('SAVE_IMAGE_USER', { id: userId, image: '' })
      }
      return json
    })
  },
  async getUserImage({ commit }, { userId }) {
    return DashboardApi.get(`users/${userId}/image`).then(json => {
      if (json.success) {
        commit('SAVE_IMAGE_USER', { id: userId, image: json.data })
      } else {
        commit('SAVE_IMAGE_USER', { id: userId, image: '' })
      }
      return json
    })
  },
  async disableUser({ commit, state }, id) {
    return DashboardApi.post(`users/${id}/disable`, {}).then(json => {
      if (json.success) {
        commit('UPDATE_USER', {
          ...state.user,
          id,
          state: USER_STATES.DISABLED,
        })
      }
      return json
    })
  },
  async enableUser({ commit }, id) {
    return DashboardApi.post(`users/${id}/enable`, {}).then(json => {
      if (json.success) {
        commit('UPDATE_USER', { ...state.user, id, state: USER_STATES.ENABLED })
      }
      return json
    })
  },
  async getUserProject({ commit }, id: number): Promise<ServerResponse> {
    return DashboardApi.get(`projects/${id}/user`).then(json => {
      if (json.success) {
        commit('SET_USER', json.data)
      }
      return json
    })
  },
  async getProjectsFromUser(
    { commit },
    userId: number,
  ): Promise<ServerResponse> {
    return DashboardApi.get(`users/${userId}/projects`).then(json => {
      if (json.success) {
        commit('SET_PROJECTS', json.data)
      }
      return json
    })
  },
  // Projects
  async getSiblingsProjects(
    { commit },
    projectId: number,
  ): Promise<ServerResponse> {
    return DashboardApi.get(`projects/${projectId}/projects`).then(json => {
      if (json.success) {
        commit('SET_PROJECTS', json.data)
      }
      return json
    })
  },
  async getProjects({ commit }, searchdata: any = {}): Promise<ServerResponse> {
    return DashboardApi.post(`projects/search`, searchdata).then(json => {
      if (json.success) {
        commit('SET_PROJECTS', json.data)
      }
      return json
    })
  },
  async getProject({ commit }, id: number): Promise<ServerResponse> {
    return DashboardApi.get(`projects/${id}`).then(json => {
      if (json.success) {
        commit('SET_PROJECT', json.data)
      }
      return json
    })
  },
  async hardAddProject(
    { commit },
    { project, userId },
  ): Promise<ServerResponse> {
    return DashboardApi.post(`projects/hard-add`, { project, userId }).then(
      json => {
        if (json.success) {
          commit('ADD_PROJECT', json.data)
        }
        return json
      },
    )
  },
  async hardDeleteProject({ commit }, id): Promise<ServerResponse> {
    return DashboardApi.delete(`projects/${id}/hard`).then(json => {
      if (json.success) {
        commit('DELETE_PROJECT', id)
      }
      return json
    })
  },
  async addProject({ commit }, { project, userId }): Promise<ServerResponse> {
    return DashboardApi.post(`projects`, { project, userId }).then(json => {
      if (json.success) {
        commit('ADD_PROJECT', json.data)
      }
      return json
    })
  },
  async updateProject({ commit }, { project, id }): Promise<ServerResponse> {
    return DashboardApi.put(`projects/${id}`, project).then(json => {
      if (json.success) {
        commit('UPDATE_PROJECT', json.data)
      }
      return json
    })
  },
  async updateProjectField(
    { commit },
    { field, value, id },
  ): Promise<ServerResponse> {
    return DashboardApi.put(`projects/${id}/field`, { field, value }).then(
      json => {
        if (json.success) {
          commit('UPDATE_PROJECT', json.data)
        }
        return json
      },
    )
  },
  async updateUserField(
    { commit },
    { field, value, id },
  ): Promise<ServerResponse> {
    return DashboardApi.put(`users/${id}/field`, { field, value }).then(
      json => {
        if (json.success) {
          commit('UPDATE_USER', json.data)
        }
        return json
      },
    )
  },
  async updateCgaField(
    { commit },
    { field, value, id },
  ): Promise<ServerResponse> {
    return DashboardApi.put(`cga/${id}/field`, { field, value }).then(json => {
      if (json.success) {
        commit('SAVE_CGA', json.data)
      }
      return json
    })
  },
  async deleteProject({ commit }, id): Promise<ServerResponse> {
    return DashboardApi.delete(`projects/${id}`).then(json => {
      if (json.success) {
        commit('DELETE_PROJECT', id)
      }
      return json
    })
  },
  async addProjectImage(
    { commit },
    { base64, projectId, extention },
  ): Promise<ServerResponse> {
    return DashboardApi.post(`projects/${projectId}/image`, {
      base64,
      extention,
    }).then(json => {
      if (json.success) {
        commit('SAVE_IMAGE_PROJECT', { id: projectId, image: json.data })
      } else {
        commit('SAVE_IMAGE_PROJECT', { id: projectId, image: '' })
      }
      return json
    })
  },
  async getProjectImage({ commit }, projectId) {
    return DashboardApi.get(`projects/${projectId}/image`).then(json => {
      if (json.success) {
        commit('SAVE_IMAGE_PROJECT', { id: projectId, image: json.data })
      } else {
        commit('SAVE_IMAGE_PROJECT', { id: projectId, image: '' })
      }
      return json
    })
  },
  async getProjectLetter({ commit }, projectId) {
    return DashboardApi.get(`projects/${projectId}/letter`).then(json => {
      if (json.success) {
        commit('SAVE_LETTER_PROJECT', json.data)
      } else {
        commit('SAVE_LETTER_PROJECT', '')
      }
      return json
    })
  },
  async getLoggedUser({ commit, state }) {
    return DashboardApi.get(`users/${state.loggedUser.id}`).then(json => {
      if (json.success) {
        commit('UPDATE_LOGGED_USER', json.data)
      }
      return json
    })
  },
  async getTva({ state }, projectId) {
    return DashboardApi.get(`projects/${projectId}/pricing`).then(json => {
      if (json.success) {
        if (json.data[0].tva) {
          state.tva = true
        } else {
          state.tva = false
        }
      }
      return json
    })
  },
  async createLetter(
    { commit, state },
    { id, pricing, bankDetails, cga, fiscalMandate },
  ) {
    return DashboardApi.post(`projects/${id}/letter`, {
      pricing,
      bankDetails,
      cga,
      fiscalMandate,
    }).then(json => {
      if (json.success) {
        commit('UPDATE_PROJECT', {
          ...state.project,
          state: PROJECT_STATES.ENABLED,
        })
        commit('SAVE_LETTER_PROJECT', json.data)
      }
      return json
    })
  },
  async updateLetter(
    { commit },
    { id, pricing, bankDetails, cga, fiscalMandate },
  ) {
    return DashboardApi.put(`projects/${id}/letter`, {
      pricing,
      bankDetails,
      cga,
      fiscalMandate,
    }).then(json => {
      if (json.success) {
        commit('SAVE_LETTER_PROJECT', json.data)
      }
      return json
    })
  },
  async validateLetterUser({ commit }, { id }) {
    return DashboardApi.post(`projects/${id}/user-validate-letter`, {}).then(
      json => {
        if (json.success) {
          commit('UPDATE_PROJECT', {
            ...state.project,
            letterState: LETTER_STATES.USER_VALIDATED,
          })
        }
        return json
      },
    )
  },
  async validateLetterAdmin({ commit }, { id, adminId }) {
    return DashboardApi.post(`projects/${id}/admin-validate-letter`, {
      adminId,
    }).then(json => {
      if (json.success) {
        commit('UPDATE_PROJECT', {
          ...state.project,
          letterState: LETTER_STATES.ADMIN_VALIDATED,
        })
      }
      return json
    })
  },
  async getCga({ commit }, id: number) {
    return DashboardApi.get(`projects/${id}/cga`).then(json => {
      if (json.success) {
        commit('SAVE_CGA', json.data[0])
      }
      return json
    })
  },
  async getBankDetails({ commit }, id: number) {
    return DashboardApi.get(`projects/${id}/bank-details`).then(json => {
      if (json.success) {
        commit('SAVE_BANK_DETAILS', json.data[0])
      }
      return json
    })
  },
  // async getPricing({ commit }, id: number) {
  //   return DashboardApi.get(`projects/${id}/pricing`).then(json => {
  //     if (json.success) {
  //       commit('SAVE_PRICING', json.data[0])
  //     }
  //     return json
  //   })
  // },
  async getFiscalMandate({ commit }, id: number) {
    return DashboardApi.get(`projects/${id}/fiscal-mandate`).then(json => {
      if (json.success) {
        commit('SAVE_FISCAL_MANDATE', json.data[0])
      }
      return json
    })
  },
  // Accounting
  async getAccountings(state, projectId: number) {
    return DashboardApi.post(`accounting/search`, { projectId }).then(json => {
      if (json.success) {
        state.commit('SAVE_ACCOUNTINGS', json.data)
      }
      return json
    })
  },
  async getAccountingByYear(state, { projectId, year }) {
    return DashboardApi.post(`accounting/search`, { projectId, year }).then(
      json => {
        if (json.success) {
          state.commit('SAVE_ACCOUNTING', json.data[0])
        }
        return json
      },
    )
  },
  async addAccounting(state, { projectId, year }) {
    return DashboardApi.post(`accounting/add`, {
      projectId,
      year,
      capital: 0,
    }).then(json => {
      if (json.success) {
        state.commit('PUSH_ACCOUNTING', json.data)
      }
      return json
    })
  },
  async lockAccounting(state, { id, comment }) {
    return DashboardApi.put(`accounting/${id}/state`, {
      comment: comment,
      state: ACCOUNTING_STATES.LOCKED,
    }).then(json => {
      if (json.success) {
        state.state.accounting.state = ACCOUNTING_STATES.LOCKED
      }
      return json
    })
  },
  async unlockAccounting(state, id) {
    return DashboardApi.put(`accounting/${id}/state`, {
      state: ACCOUNTING_STATES.ENABLED,
    }).then(json => {
      if (json.success) {
        state.state.accounting.state = ACCOUNTING_STATES.ENABLED
      }
      return json
    })
  },
  async updateAccountingField(
    { commit },
    { field, value, id },
  ): Promise<ServerResponse> {
    return DashboardApi.put(`accounting/${id}/field`, { field, value }).then(
      json => {
        if (json.success) {
          commit('SAVE_ACCOUNTING', json.data)
        }
        return json
      },
    )
  },
  async editCapital(state, { id, capital }) {
    return DashboardApi.put(`accounting/${id}/capital`, {
      capital,
    }).then(json => {
      if (json.success) {
        state.commit('SAVE_ACCOUNTING', json.data)
      }
      return json
    })
  },
  async editIncome(state, income: AccountingIncomes) {
    console.log('editIncome')
    return DashboardApi.put(`incomes/${income.id}`, income).then(json => {
      if (json.success) {
        state.commit('UPDATE_INCOME', json.data)
      }
      return json
    })
  },
  async getIncomes(state, financialAccountingId: number) {
    return DashboardApi.post(`incomes/search`, { financialAccountingId }).then(
      json => {
        if (json.success) {
          state.commit('SET_INCOMES', json.data)
        }
        return json
      },
    )
  },
  async addIncome(state, income: AccountingIncomes) {
    return DashboardApi.post(`incomes/`, income).then(json => {
      if (json.success) {
        state.commit('ADD_INCOME', json.data)
      }
      return json
    })
  },
  async deleteIncome(state, id: number) {
    return DashboardApi.delete(`incomes/${id}`).then(json => {
      if (json.success) {
        state.commit('DELETE_INCOME', id)
      }
      return json
    })
  },
  async editCaution(state, caution: AccountingCautions) {
    return DashboardApi.put(`cautions/${caution.id}`, caution).then(json => {
      if (json.success) {
        state.commit('UPDATE_CAUTION', json.data)
      }
      return json
    })
  },
  async getCautions(state, projectId: number) {
    return DashboardApi.post(`cautions/search`, { projectId }).then(json => {
      if (json.success) {
        state.commit('SET_CAUTIONS', json.data)
      }
      return json
    })
  },
  async addCaution(state, caution: AccountingCautions) {
    return DashboardApi.post(`cautions/`, caution).then(json => {
      if (json.success) {
        state.commit('ADD_CAUTION', json.data)
      }
      return json
    })
  },
  async deleteCaution(state, id: number) {
    return DashboardApi.delete(`cautions/${id}`).then(json => {
      if (json.success) {
        state.commit('DELETE_CAUTION', id)
      }
      return json
    })
  },
  async editExpense(state, income: AccountingExpenses) {
    return DashboardApi.put(`expenses/${income.id}`, income).then(json => {
      if (json.success) {
        state.commit('UPDATE_EXPENSE', json.data)
      }
      return json
    })
  },
  async getExpenses(state, financialAccountingId: number) {
    return DashboardApi.post(`expenses/search`, { financialAccountingId }).then(
      json => {
        if (json.success) {
          state.commit('SET_EXPENSES', json.data)
        }
        return json
      },
    )
  },
  async addExpense(state, expense: AccountingExpenses) {
    return DashboardApi.post(`expenses/`, expense).then(json => {
      if (json.success) {
        state.commit('ADD_EXPENSE', json.data)
      }
      return json
    })
  },
  async deleteExpense(state, id: number) {
    return DashboardApi.delete(`expenses/${id}`).then(json => {
      if (json.success) {
        state.commit('DELETE_EXPENSE', id)
      }
      return json
    })
  },
  async editLoan(state, income: AccountingLoans) {
    return DashboardApi.put(`loans/${income.id}`, income).then(json => {
      if (json.success) {
        state.commit('UPDATE_LOAN', json.data)
      }
      return json
    })
  },
  async getLoans(state, financialAccountingId: number) {
    return DashboardApi.post(`loans/search`, { financialAccountingId }).then(
      json => {
        if (json.success) {
          state.commit('SET_LOANS', json.data)
        }
        return json
      },
    )
  },
  async addLoan(state, loan: AccountingLoans) {
    return DashboardApi.post(`loans/`, loan).then(json => {
      if (json.success) {
        state.commit('ADD_LOAN', json.data)
      }
      return json
    })
  },
  async deleteLoan(state, id: number) {
    return DashboardApi.delete(`loans/${id}`).then(json => {
      if (json.success) {
        state.commit('DELETE_LOAN', id)
      }
      return json
    })
  },
  async createAccounting(state, projectId: number) {
    return DashboardApi.post(`accounting/init/${projectId}`, {}).then(json => {
      if (json.success) {
        state.commit('PUSH_ACCOUNTING', json.data)
      }
      return json
    })
  },
  async generateAccountingReport(state, id: number) {
    return DashboardApi.post(`accounting/${id}/report`, {}).then(json => {
      return json
    })
  },
  async addPreAccounting(state, { id, date }) {
    return DashboardApi.post(`accounting/${id}/addprereport`, { date }).then(
      json => {
        if (json.success) {
          state.commit('SAVE_ACCOUNTING', json.data.accounting)
          state.commit('SET_INCOMES', json.data.incomes)
          state.commit('SET_EXPENSES', json.data.expenses)
          state.commit('SET_LOANS', json.data.loans)
        }
        return json
      },
    )
  },
  async downloadPreAccounting(state, { id, date }) {
    return DashboardApi.post(`accounting/${id}/downloadprereport`, {
      date,
    }).then(json => {
      if (json.success) {
        state.commit('SAVE_ACCOUNTING', json.data.accounting)
      }
      return json
    })
  },
  async getLetter(state, id: number) {
    return DashboardApi.get(`projects/${id}/letter`).then(json => {
      return json
    })
  },
  async validateLetter(
    state,
    { id, pricing, bankDetails, cga, fiscalMandate },
  ) {
    return DashboardApi.post(`projects/${id}/validate-letter`, {
      pricing,
      bankDetails,
      cga,
      fiscalMandate,
    }).then(json => {
      if (json.success) {
        state.state.project.state = PROJECT_STATES.ENABLED
      }
      return json
    })
  },
  async completeProject(state, { id }) {
    return DashboardApi.post(`projects/${id}/complete-project`, {}).then(
      json => {
        if (json.success) {
          state.state.projects.forEach(p => {
            if (p.id === id) {
              p.letterState = LETTER_STATES.COMPLETED
            }
          })
          state.state.project.letterState = LETTER_STATES.COMPLETED
        }
        return json
      },
    )
  },
  async revertProject(state, { id }) {
    return DashboardApi.post(`projects/${id}/revert-project`, {}).then(json => {
      if (json.success) {
        state.state.projects.forEach(p => {
          if (p.id === id) {
            p.letterState = LETTER_STATES.USER_VALIDATED
          }
        })
        state.state.project.letterState = LETTER_STATES.USER_VALIDATED
      }
      return json
    })
  },
  // Others
  async saveViewUsersPage(state, viewUsersPage: number) {
    state.commit('SAVE_VIEW_USERS_PAGE', viewUsersPage)
  },
  async sendHelp(state, { text, userId }) {
    return DashboardApi.post(`users/${userId}/request-help`, {
      text,
    }).then(json => {
      return json
    })
  },
  async getTuto({ commit }) {
    return DashboardApi.get(`projects/tuto`).then(json => {
      if (json.success) {
        commit('SAVE_TUTO', json.data)
      }
      return json
    })
  },
  async getUserTuto({ commit }) {
    return DashboardApi.get(`projects/user-tuto`).then(json => {
      if (json.success) {
        commit('SAVE_TUTO', json.data)
      }
      return json
    })
  },
}

const mutations: MutationTree<State> = {
  // Auth
  SET_TOKEN(state, token: string) {
    state.token = token
    localStorage.setItem('token', token)
  },
  SET_SESSION_EXPIRATION(state, sessionExpiration: number) {
    state.sessionExpiration = sessionExpiration
    localStorage.setItem('sessionExpiration', JSON.stringify(sessionExpiration))
  },
  SET_LOGGED_USER(state, loggedUser: User) {
    state.loggedUser = loggedUser
    localStorage.setItem('loggedUser', JSON.stringify(loggedUser))
  },
  UPDATE_LOGGED_USER(state, user: User) {
    if (state.loggedUser.id === user.id) {
      state.loggedUser = { ...state.loggedUser, ...user }
      localStorage.setItem('loggedUser', JSON.stringify(state.loggedUser))
    }
  },
  SET_TOKEN_DATA(state, token: string): TokenData {
    if (!token) {
      return (state.tokendata = DEFAULT_TOKEN_DATA)
    }
    const base64Url = token.split('.')[1]
    if (!base64Url) {
      return (state.tokendata = DEFAULT_TOKEN_DATA)
    }
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const tokendata = JSON.parse(window.atob(base64) || '')

    if (tokendata && tokendata.user && tokendata.exp) {
      return (state.tokendata = tokendata)
    }
    return (state.tokendata = DEFAULT_TOKEN_DATA)
  },
  // Users
  SET_USERS(state, users: User[]) {
    state.users = users
  },
  SET_USER(state, user: User) {
    state.user = user
  },
  ADD_USER(state, user: User) {
    state.users.push(user)
  },
  UPDATE_USER(state, user: User) {
    state.users = state.users.map(u =>
      u.id === user.id ? { ...u, ...user } : u,
    )
    if (state.loggedUser.id === user.id) {
      state.loggedUser = user
    }
    if (state.user.id === user.id) {
      state.user = user
    }
  },
  DELETE_USER(state, id: number) {
    state.users = state.users.filter(u => u.id !== id)
  },
  SAVE_IMAGE_USER(state, { id, image }) {
    state.users = state.users.map(u => (u.id === id ? { ...u, image } : u))
    if (state.user.id === id) {
      state.user.image = image
    }
  },
  // Projects
  SET_PROJECTS(state, projects: Project[]) {
    state.projects = projects
  },
  SET_PROJECT(state, project: Project) {
    state.project = project
  },
  ADD_PROJECT(state, project: Project) {
    state.projects.push(project)
  },
  UPDATE_PROJECT(state, project: Project) {
    state.projects = state.projects.map(p =>
      p.id === project.id ? project : p,
    )
    if (state.project.id === project.id) {
      state.project = project
    }
  },
  DELETE_PROJECT(state, id: number) {
    state.projects = state.projects.filter(p => p.id !== id)
  },
  SAVE_IMAGE_PROJECT(state, { id, image }) {
    state.projects = state.projects.map(p =>
      p.id === id ? { ...p, image } : p,
    )
    if (state.project.id === id) {
      state.project.image = image
    }
    state.projectImage = image
  },
  SAVE_LETTER_PROJECT(state, base64: string) {
    state.letter = base64
  },
  SAVE_ACCOUNTINGS(state, accountings: AccountingFinancial[]) {
    state.accountings = accountings
  },
  SAVE_ACCOUNTING(state, accounting: AccountingFinancial) {
    state.accounting = accounting
  },
  PUSH_ACCOUNTING(state, accounting: AccountingFinancial) {
    state.accountings.push(accounting)
    state.accountings = state.accountings.sort(
      (a, b) => (b.year || 0) - (a.year || 0),
    )
  },
  SET_EXPENSES(state, expenses: AccountingExpenses[]) {
    state.expenses = expenses
  },
  ADD_EXPENSE(state, addedExpense: AccountingExpenses) {
    state.expenses.push(addedExpense)
  },
  DELETE_EXPENSE(state, id: number) {
    state.expenses = state.expenses.filter(e => e.id !== id)
  },
  UPDATE_EXPENSE(state, updatedExpense: AccountingExpenses) {
    state.expenses = state.expenses.map(e =>
      e.id === updatedExpense.id ? updatedExpense : e,
    )
  },
  SET_INCOMES(state, incomes: AccountingIncomes[]) {
    state.incomes = incomes
  },
  ADD_INCOME(state, addedIncome: AccountingIncomes) {
    state.incomes.push(addedIncome)
  },
  DELETE_INCOME(state, id: number) {
    state.incomes = state.incomes.filter(i => i.id !== id)
  },
  UPDATE_INCOME(state, updatedIncome: AccountingIncomes) {
    state.incomes = state.incomes.map(i =>
      i.id === updatedIncome.id ? updatedIncome : i,
    )
  },
  SET_CAUTIONS(state, cautions: AccountingCautions[]) {
    state.cautions = cautions
  },
  ADD_CAUTION(state, addedCaution: AccountingCautions) {
    state.cautions.push(addedCaution)
  },
  DELETE_CAUTION(state, id: number) {
    state.cautions = state.cautions.filter(i => i.id !== id)
  },
  UPDATE_CAUTION(state, updatedCaution: AccountingCautions) {
    state.cautions = state.cautions.map(i =>
      i.id === updatedCaution.id ? updatedCaution : i,
    )
  },
  SET_LOANS(state, loans: AccountingLoans[]) {
    state.loans = loans
  },
  ADD_LOAN(state, addedLoan: AccountingLoans) {
    state.loans.push(addedLoan)
  },
  DELETE_LOAN(state, id: number) {
    state.loans = state.loans.filter(l => l.id !== id)
  },
  UPDATE_LOAN(state, updatedLoan: AccountingLoans) {
    state.loans = state.loans.map(l =>
      l.id === updatedLoan.id ? updatedLoan : l,
    )
  },
  SAVE_CGA(state, cga: Cga) {
    state.cga = cga
  },
  SAVE_BANK_DETAILS(state, bankDetails: BankDetails) {
    state.bankDetails = bankDetails
  },
  // SAVE_PRICING(state, pricing: Pricing) {
  //   state.pricing = pricing
  // },
  SAVE_FISCAL_MANDATE(state, fiscalMandate: FiscalMandate) {
    state.fiscalMandate = fiscalMandate
  },
  // Others
  SAVE_VIEW_USERS_PAGE(state, page: number) {
    state.viewUsersPage = page
  },
  SAVE_TUTO(state, tuto: string) {
    state.tuto = tuto
  },
}

export { state, getters, actions, mutations }
