import { DigInputText } from '@digithia/input/text'
import moment, { Moment } from 'moment'

export class Utils {
  get locale(): string {
    return moment.locale()
  }

  set locale(lang: string) {
    moment.locale(lang)
  }

  constructor() {
    this.locale = 'fr'
  }

  static numberRegExp = /^[0-9]*[.,]{0,1}[0-9]*$/

  toFirstUpperCase(text: string) {
    if (typeof text !== 'string') return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  UTCToMoment(date: string | undefined): Moment {
    if (!date) return moment()
    return moment(date)
  }

  nowToMoment(): Moment {
    return moment()
  }

  momentToUTC(moment: Moment): string {
    return moment.toISOString()
  }

  nowToUTC(): string {
    return moment().toISOString()
  }

  HTMLToServer(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('YYYY/MM/DD')
  }

  UTCToHuman(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('MMMM YYYY')
  }

  UTCToYear(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('YYYY')
  }

  UTCToMonth(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('MMMM')
  }

  UTCToDay(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('DDDD')
  }

  UTCToShort(date: string | undefined | null): string {
    if (!date) return ''
    return moment(date).format('L')
  }

  dateToLastDayOfTheYear(date: string): string {
    if (!date) return ''
    return moment(date)
      .set('M', 11)
      .set('D', 31)
      .format('YYYY-MM-DD')
  }

  /**
   * Wikipedia :
    An IBAN is validated by converting it into an integer and performing a basic mod-97 operation (as described in ISO 7064) on it. If the IBAN is valid, the remainder equals 1.[Note 1] The algorithm of IBAN validation is as follows:[8]

    Check that the total IBAN length is correct as per the country.
    If not, the IBAN is invalid
    Move the four initial characters to the end of the string
    Replace each letter in the string with two digits,
    thereby expanding the string, where A = 10, B = 11, ..., Z = 35
    Interpret the string as a decimal integer and compute the remainder of that number on division by 97

    If the remainder is 1, the check digit test is passed and the IBAN might be valid.

    Example (fictitious United Kingdom bank, sort code 12-34-56, account number 98765432):

    • IBAN: 		GB82 WEST 1234 5698 7654 32
    • Rearrange: 		W E S T12345698765432 G B82
    • Convert to integer: 		3214282912345698765432161182
    • Compute remainder: 		3214282912345698765432161182 	mod 97 = 1
    FR76 3000 6000 0112 3456 7890 189
   */
  static checkIBAN(iban: string, countryLength = 27): boolean {
    if (!iban) return true
    const trimIBAN = iban.replace(/ /g, '')
    if (trimIBAN.length !== countryLength) {
      return false
    }
    const reversedIBAN = `${trimIBAN.slice(4, trimIBAN.length)}${trimIBAN.slice(
      0,
      4,
    )}`
    let integerIBAN = ''
    for (const char of reversedIBAN) {
      if (isNaN(+char)) {
        integerIBAN += (char.codePointAt(0) || 0) - 55
      } else {
        integerIBAN += char
      }
    }

    if (BigInt(integerIBAN) % BigInt(97) === BigInt(1)) {
      return true
    } else {
      return false
    }
  }

  static checkBIC(bic: string): boolean {
    if (!bic) return true
    const trimBIC = bic.replace(/ /g, '')
    if (trimBIC.length >= 8 && trimBIC.length <= 11) {
      return true
    }
    return false
  }

  static luhnFormula(value: string) {
    let sum = 0
    let it = false
    for (let i = value.length - 1; i >= 0; i--) {
      const n = +value[i]
      console.log(n, Utils.luhnTransformationTable.get(n))
      if (it) {
        sum += Utils.luhnTransformationTable.get(n) || 0
        it = false
      } else {
        sum += n
        it = true
      }
    }
    return sum
  }

  static luhnTransformationTable = new Map<number, number>([
    [0, 0],
    [1, 2],
    [2, 4],
    [3, 6],
    [4, 8],
    [5, 1],
    [6, 3],
    [7, 5],
    [8, 7],
    [9, 9],
  ])

  static checkSiret(siret: string): boolean {
    if (!siret) return true
    const trimSiret = siret.replace(/ /g, '')
    if (trimSiret.length === 14) {
      return true
      // if (Utils.luhnFormula(siret) % 10 === 0) {
      //   return true
      // }
    }
    return false
  }

  static checkInputsValidity(selector: string): boolean {
    const inputs = document.querySelectorAll(selector)
    let valid = true
    for (const i in inputs) {
      const input = inputs[i] as DigInputText
      if (input && input.checkValidity && !input.checkValidity()) {
        valid = false
      }
    }
    return valid
  }

  static resetInputsValidity(selector: string): void {
    const inputs = document.querySelectorAll(selector)
    for (const i in inputs) {
      const input = inputs[i] as DigInputText
      if (input && input.reset) {
        input.reset()
      }
    }
  }
}

export default new Utils()
