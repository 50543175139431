

















import { Component, Vue } from 'vue-property-decorator'
import TheNavItem from '@/components/navigation/TheNavItem.vue'
import TheMenuButton from '@/components/navigation/TheMenuButton.vue'

@Component({
  components: {
    TheNavItem,
    TheMenuButton,
  },
})
export default class TheNav extends Vue {
  toggle() {
    this.$store.dispatch('nav/toggle')
  }

  changeLocale() {
    let lang = 'fr'
    if (this.$store.getters['locales/lang'] === 'fr') {
      lang = 'en'
    }
    this.$store.dispatch('locales/changeLang', lang)
  }

  logout() {
    this.$store.dispatch('toaster/toast', {
      message: `Au revoir ${this.$store.getters.loggedUser.username}`,
      type: 'success',
    })
    this.$store.dispatch('logout')
    this.$router.push({ name: 'Login' })
  }

  goTo(to: string) {
    this.$router.push(to).catch(error => {
      if (
        error.name !== 'NavigationDuplicated' &&
        !error.message.includes(
          'Avoided redundant navigation to current location',
        )
      ) {
        console.error(error)
      }
    })
  }
}
