






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class BaseToggleButton extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
}
