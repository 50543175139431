























import { Component, Vue } from 'vue-property-decorator'
import { Toast } from '../../store/toaster'

@Component({})
export default class TheToaster extends Vue {
  get toasts(): Toast[] {
    return this.$store.getters['toaster/toasts']
  }

  remove(toast: Toast) {
    this.$store.dispatch('toaster/remove', toast)
  }

  isInfo(toast: Toast): boolean {
    return (
      toast.type === 'info' ||
      !this.isSuccess(toast) ||
      !this.isWarning(toast) ||
      !this.isError(toast)
    )
  }

  isSuccess(toast: Toast): boolean {
    return toast.type === 'success'
  }

  isWarning(toast: Toast): boolean {
    return toast.type === 'warning'
  }

  isError(toast: Toast): boolean {
    return toast.type === 'error'
  }
}
